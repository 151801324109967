import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { $T } from '@translation-keys';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, MatIconModule, FlexLayoutModule],
  selector: 'app-sterilization-section',
  templateUrl: './sterilization-section.component.html',
  styleUrls: ['./sterilization-section.component.scss'],
})
export class SterilizationSectionComponent {
  $T = $T;
}
