/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, InjectionToken, OnInit } from '@angular/core';

export const COOKIE_DOMAIN_TOKEN = new InjectionToken<string>('cookieDomain');
const USER_LANGUAGE = 'user_language';

declare const dr_generated_banner;
declare const dr_cookiebanner_options;
declare const dr_existsLanguage;
declare const window;
declare let dr_onShowCategoryDetailsHide;

@Component({
  selector: 'wuh-consent-manager',
  templateUrl: './consent-manager.component.html',
  styleUrls: ['./consent-manager.component.scss'],
})
export class WuhConsentManagerComponent implements OnInit {
  private readonly language = localStorage.getItem(USER_LANGUAGE);

  constructor(@Inject(COOKIE_DOMAIN_TOKEN) private readonly cookieDomain: string) {}

  ngOnInit(): void {
    this.setupOverlay();
    this.setLanguage();
    this.setupDetailsButton();

    if (this.cookieDomain) {
      dr_cookiebanner_options.cookieDomain = this.cookieDomain;
    }

    window.cookieconsent.initialise(dr_cookiebanner_options);
    this.stylePrivacyLink();
  }

  private setupOverlay(): void {
    const defaultOnPopupOpen = dr_cookiebanner_options.onPopupOpen;
    dr_cookiebanner_options.onPopupOpen = function () {
      defaultOnPopupOpen();

      const overlay = document.getElementById('dr_blocking_div');
      if (overlay) {
        overlay.style.display = 'block';
      }
    };

    const defaultOnPopupClose = dr_cookiebanner_options.onPopupClose;
    dr_cookiebanner_options.onPopupClose = function () {
      defaultOnPopupClose();

      const overlay = document.getElementById('dr_blocking_div');
      if (overlay) {
        overlay.style.display = 'none';
      }
    };
  }

  private setLanguage() {
    const existingLanguage = dr_existsLanguage(this.language);
    if (existingLanguage) {
      dr_cookiebanner_options.overrideHTML = dr_generated_banner[existingLanguage];
    }
  }

  private stylePrivacyLink() {
    const privacyLink = document.getElementById('dr-privacylink-id');
    if (privacyLink) {
      privacyLink.style.textDecoration = 'underline';
    }
  }

  private setupDetailsButton() {
    const defaultOnClickDetails = dr_onShowCategoryDetailsHide;

    dr_onShowCategoryDetailsHide = function () {
      defaultOnClickDetails();

      const detailsButton = document.getElementById('dr-hiddencat-btn-show-more-hide');
      detailsButton.style.display = 'block';
    };
  }
}
