import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SidenavComponent } from '../../sidenav/sidenav.component';
import { ToolbarComponent } from '../../toolbar/toolbar.component';
import { BenefitsSectionComponent } from './benefits-section/benefits-section.component';
import { CallToActionSectionComponent } from './call-to-action-section/call-to-action-section.component';
import { DevicesSectionComponent } from './devices-section/devices-section.component';
import { FooterSectionSharingComponent } from './footer-section-sharing/footer-section-sharing.component';
import { FooterSectionSocialComponent } from './footer-section-social/footer-section-social.component';
import { FooterSectionComponent } from './footer-section/footer-section.component';
import { HeaderSectionComponent } from './header-section/header-section.component';
import { HowToSectionComponent } from './how-to-section/how-to-section.component';
import { InformationSectionComponent } from './information-section/information-section.component';
import { ServicesSectionComponent } from './services-section/services-section.component';

@Component({
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    CommonModule,
    DevicesSectionComponent,
    HeaderSectionComponent,
    BenefitsSectionComponent,
    CallToActionSectionComponent,
    InformationSectionComponent,
    FooterSectionSharingComponent,
    FooterSectionSocialComponent,
    FlexLayoutModule,
    FooterSectionComponent,
    MatSelectModule,
    ServicesSectionComponent,
    ToolbarComponent,
    HowToSectionComponent,
    RouterLink,
    SidenavComponent,
  ],
  selector: 'app-landing-page',
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss'],
  providers: [],
})
export class LandingPage {}
