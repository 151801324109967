<app-sidenav active="connect-devices">
  <div class="how-to-section" id="first">
    <app-how-to-section></app-how-to-section>
  </div>
  <div class="information-section">
    <app-information-section></app-information-section>
  </div>
  <div class="footer-section-sharing">
    <app-footer-section-sharing></app-footer-section-sharing>
  </div>
  <div class="footer-section-social">
    <app-footer-section-social></app-footer-section-social>
  </div>
  <div class="footer-section">
    <app-footer-section></app-footer-section>
  </div>
</app-sidenav>
