import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { $T } from '@translation-keys';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { redirectToLogin, redirectToRegistration } from '../app-redirection/redirection';
import { LANGUAGES, LanguageService } from '../language.service';
import { ToolbarComponent } from '../toolbar/toolbar.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    MatSelectModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    ToolbarComponent,
    RouterLink,
    RouterLink,
  ],
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  $T = $T;

  @Input() active: string;
  @Input() containerClass = '';
  languages = LANGUAGES;
  selectedLanguage: string;

  onLogin = redirectToLogin;
  onRegister = redirectToRegistration;
  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.selectedLanguage = this.languageService.selectedLanguage;
  }

  onLanguageChange() {
    this.languageService.changeLanguage(this.selectedLanguage);
  }
}
