import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { $T } from '@translation-keys';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { LanguageService } from '../../../language.service';
import { HowToSectionStepComponent } from './how-to-section-step/how-to-section-step.component';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, MatButtonModule, FlexLayoutModule, HowToSectionStepComponent],
  selector: 'app-how-to-section',
  templateUrl: './how-to-section.component.html',
  styleUrls: ['./how-to-section.component.scss'],
})
export class HowToSectionComponent {
  $T = $T;

  constructor(private languageService: LanguageService) {}

  onDownloadFlyer() {
    const language = this.languageService.getUserLanguage();
    if (language === 'de') {
      window.open('https://iodentstatic.blob.core.windows.net/documents/howto_de.pdf');
    } else {
      window.open('https://iodentstatic.blob.core.windows.net/documents/howto_en.pdf');
    }
  }
}
