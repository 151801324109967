import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { COOKIE_DOMAIN_TOKEN, WuhConsentManagerComponent } from '@consent-manager/*';
import { environment } from '../environments';

const translateArgs = {
  loader: {
    provide: TranslateLoader,
    useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
    deps: [HttpClient],
  },
};

registerLocaleData(localeDe);

@NgModule({
  declarations: [AppComponent, WuhConsentManagerComponent],
  imports: [BrowserModule, AppRoutingModule, TranslateModule.forRoot(translateArgs), HttpClientModule, BrowserAnimationsModule],
  bootstrap: [AppComponent, WuhConsentManagerComponent],
  providers: [{ provide: COOKIE_DOMAIN_TOKEN, useValue: environment.cookieDomain }],
})
export class AppModule {}
