import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConnectDevicesPage } from './pages/connect-devices-page/connect-devices.page';
import { LandingPage } from './pages/landing-page/landing.page';
import { PrivacyPolicyPage } from './pages/privacy-policy-page/privacy-policy.page';
import { ScopeOfServicePage } from './pages/scope-of-service/scope-of-service.page';
import { ServiceToolTermsOfUsePage } from './pages/servicetool-terms-of-use-page/servicetool-terms-of-use.page';
import { ServiceToolPage } from './pages/servicetool/servicetool.page';
import { TermsOfUsePage } from './pages/terms-of-use-page/terms-of-use.page';

const routes: Routes = [
  {
    path: '',
    component: LandingPage,
  },
  {
    path: 'connect-devices',
    component: ConnectDevicesPage,
  },
  {
    path: 'scope-of-service',
    component: ScopeOfServicePage,
  },
  {
    path: 'terms-of-use',
    component: TermsOfUsePage,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: 'ST/scope-of-service',
    component: ServiceToolPage,
  },
  {
    path: 'ST/terms-of-use',
    component: ServiceToolTermsOfUsePage,
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'top' }),
    LandingPage,
    TermsOfUsePage,
    PrivacyPolicyPage,
    ConnectDevicesPage,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
