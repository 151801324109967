import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CustomIconsService {
  constructor(private iconReg: MatIconRegistry, private sanitizer: DomSanitizer) {}

  /**
   * Registers custom icons for use in mat-icon component.
   */
  register() {
    this.iconReg.addSvgIcon('iodent-logo', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/iodent-logo.svg'));
    this.iconReg.addSvgIcon('arrow-down', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic-arrows-bottom.svg'));
    this.iconReg.addSvgIcon('improved-efficiency', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/clock.svg'));
    this.iconReg.addSvgIcon('peace-of-mind', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/shield.svg'));
    this.iconReg.addSvgIcon('security', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/lock.svg'));
    this.iconReg.addSvgIcon('steri', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/steri.svg'));
    this.iconReg.addSvgIcon('int-assist', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/int-assist.svg'));
    this.iconReg.addSvgIcon('implantmed', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/implantmed.svg'));
    this.iconReg.addSvgIcon('facebook', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/facebook.svg'));
    this.iconReg.addSvgIcon('instagram', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/instagram.svg'));
    this.iconReg.addSvgIcon('linkedin', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/linkedin.svg'));
    this.iconReg.addSvgIcon('youtube', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/youtube.svg'));
    this.iconReg.addSvgIcon('arrow-top', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/arrow-top.svg'));
    this.iconReg.addSvgIcon('arrow-right', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/arrow-right.svg'));
    this.iconReg.addSvgIcon('arrow-right-white', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/arrow-right-white.svg'));
    this.iconReg.addSvgIcon('print', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/print.svg'));
    this.iconReg.addSvgIcon('share', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/share.svg'));
    this.iconReg.addSvgIcon('download', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/download.svg'));
    this.iconReg.addSvgIcon('esagono', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/esagono.svg'));
  }
}
