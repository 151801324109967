import { environment } from '../../environments/environment';

export const redirectToLogin = () => {
  window.location.href = appRedirection.loginUrl;
};

export const redirectToRegistration = () => {
  window.location.href = appRedirection.registerUrl;
};

const getB2CRedirectionForPolicy = (policy: string) =>
  /* eslint-disable */
  `https://${environment.B2C.tenantName}.b2clogin.com/${environment.B2C.tenantName}.onmicrosoft.com/oauth2/v2.0/authorize?` +
  `p=${policy}` +
  `&client_id=${environment.B2C.clientId}` +
  '&nonce=defaultNonce' +
  '&response_type=id_token%20token' +
  `&redirect_uri=https%3A%2F%2F${environment.B2C.appUrl}` +
  `&scope=openid%20https://${environment.B2C.tenantName}.onmicrosoft.com/${environment.B2C.applicationName}/wuh_iot_access`;
/* eslint-enable */

const appRedirection = {
  loginUrl: getB2CRedirectionForPolicy(environment.B2C.loginPolicy),
  registerUrl: getB2CRedirectionForPolicy(environment.B2C.registerPolicy),
};
