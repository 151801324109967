import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { $T } from '@translation-keys';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { Subscription } from 'rxjs';
import { redirectToLogin, redirectToRegistration } from '../app-redirection/redirection';
import { LANGUAGES, LanguageService } from '../language.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    MatSelectModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    RouterLink,
    MatMenuModule,
    RouterLink,
  ],
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  $T = $T;

  @Input() isMobile: boolean;
  @Input() sidenav: MatSidenav;
  @Input() active: string;
  hover = '';
  top = 160;

  private subscription: Subscription;

  languages = LANGUAGES;
  selectedLanguage: string;

  onLogin = redirectToLogin;
  onRegister = redirectToRegistration;

  constructor(private languageService: LanguageService, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.selectedLanguage = this.languageService.selectedLanguage;
  }

  @HostListener('document:mousewheel', ['$event'])
  onScrollEvent() {
    const topElement = document.getElementById('first') as HTMLElement;
    const topPosition = topElement.getBoundingClientRect().top;

    if (this.top < topPosition) {
      this.hover = 'hover';
    } else {
      this.hover = '';
    }
    this.top = topPosition;
  }

  mouseoutToolbar(event) {
    if (event.clientY >= 68 + 72) {
      this.hover = '';
    }
  }
  onLanguageChange() {
    this.languageService.changeLanguage(this.selectedLanguage);
  }

  toggle(sidenav: MatSidenav) {
    if (sidenav.opened) {
      void sidenav.close();
      this.subscription.unsubscribe();
    } else {
      this.subscription = new Subscription();
      this.subscription.add(
        this.breakpointObserver.observe(['(min-width:640px)']).subscribe((state: BreakpointState) => {
          if (state.matches) {
            document.getElementById('toggle').click();
            this.subscription.unsubscribe();
          }
        }),
      );
      void sidenav.open();
    }
  }
}
