<app-sidenav active="home">
  <div class="header-section" id="first">
    <app-header-section></app-header-section>
  </div>
  <div class="services-section">
    <app-services-section></app-services-section>
  </div>
  <div class="devices-section">
    <app-devices-section></app-devices-section>
  </div>
  <div class="benefits-section">
    <app-benefits-section></app-benefits-section>
  </div>
  <div class="call-to-action-section">
    <app-call-to-action-section></app-call-to-action-section>
  </div>
  <div class="information-section">
    <app-information-section></app-information-section>
  </div>
  <div class="footer-section-sharing">
    <app-footer-section-sharing></app-footer-section-sharing>
  </div>
  <div class="footer-section-social">
    <app-footer-section-social></app-footer-section-social>
  </div>
  <div class="footer-section">
    <app-footer-section></app-footer-section>
  </div>
</app-sidenav>
