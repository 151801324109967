import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { CustomIconsService } from './custom-icons.service';
import { LanguageService } from './language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly matIconReg: MatIconRegistry,
    private languageService: LanguageService,
    customIconsService: CustomIconsService,
  ) {
    customIconsService.register();
    const currentLanguage = this.languageService.getUserLanguage();
    this.languageService.setBrowserLanguage(currentLanguage);
  }

  ngOnInit(): void {
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined', 'filled-symbol');
  }
}
