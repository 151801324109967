// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  B2C: {
    tenantName: '#{b2c_tenantName}#',
    applicationName: '#{b2c_applicationName}#',
    clientId: '#{b2c_clientId}#',
    appUrl: '#{b2c_appUrl}#',
    loginPolicy: '#{b2c_loginPolicy}#',
    registerPolicy: '#{b2c_registerPolicy}#',
  },
  cookieDomain: '#{cookieDomain}#',
};
