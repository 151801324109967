import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { $T } from '@translation-keys';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  standalone: true,
  imports: [TranslateModule, MatCardModule, FlexLayoutModule, MatIconModule],
  selector: 'app-services-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.scss'],
})
export class ServicesSectionComponent {
  $T = $T;
}
