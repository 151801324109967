import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { $T } from '@translation-keys';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, MatButtonModule, FlexLayoutModule, RouterModule, MatCardModule],
  selector: 'app-footer-section-social',
  templateUrl: './footer-section-social.component.html',
  styleUrls: ['./footer-section-social.component.scss'],
})
export class FooterSectionSocialComponent {
  $T = $T;
}
