import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const dr_webcare_privacynotice;

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private readonly userLanguageSettingKey = 'user_language';
  selectedLanguage: string;

  constructor(private translateService: TranslateService, @Inject(DOCUMENT) private document: Document) {}

  getUserLanguage() {
    let language = localStorage.getItem(this.userLanguageSettingKey);

    if (language === null) {
      language = this.getBrowserLanguage();
      return language;
    } else {
      return language;
    }
  }

  getBrowserLanguage() {
    const browserLanguage = navigator.language;
    if (browserLanguage.startsWith(LANGUAGES[0].language)) {
      return LANGUAGES[0].language;
    } else if (browserLanguage.startsWith(LANGUAGES[1].language)) {
      return LANGUAGES[1].language;
    } else if (browserLanguage.startsWith(LANGUAGES[2].language)) {
      return LANGUAGES[2].language;
    } else {
      return LANGUAGES[1].language;
    }
  }

  setBrowserLanguage(language: string) {
    this.setLanguage(language);
  }

  changeLanguage(newLanguage: string) {
    const language = LANGUAGES.some(x => x.language === newLanguage) ? newLanguage : 'en';

    this.setLanguage(language);
    // integrate privacy policy again after language change
    dr_webcare_privacynotice.integrate();
  }

  private setLanguage(language: string) {
    localStorage.setItem(this.userLanguageSettingKey, language);
    this.translateService.setDefaultLang('en');
    this.translateService.use(language);
    this.selectedLanguage = language;
    this.document.documentElement.lang = language;
  }
}

export const LANGUAGES: any = [
  {
    languageText: 'Deutsch',
    language: 'de',
  },
  {
    languageText: 'English',
    language: 'en',
  },
  {
    languageText: 'Italiano',
    language: 'it',
  },
  {
    languageText: 'Français',
    language: 'fr',
  },
  {
    languageText: 'Español',
    language: 'es',
  },
];
